import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {MapDataContext} from "../index";
import {First} from "../../../../manager/carto3";
import {DateMode} from "../../../../data/main/state";
import {MainDataContext} from "../../../main";
import {colorClasses} from "../layers/sales_h3";

const styles = {
    root: {
      width: "60%",
      maxWidth: "300px",
    },
    colorHex: {
        width: "48px",
        height: "48px",
        clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
    },
    legendsBox: {
        display: "flex",
        flexDirection: "row",
        margin: "1rem 0",
    },
    legendsText: {
        width: "200px",
        display: "flex",
        alignItems: "center",
        marginLeft: "1rem",
    }
}

const MapLegendView = ({open, onClose}) => {

    const { state: mainState } = useContext(MainDataContext)
    const { state } = useContext(MapDataContext)
    const [data, setData] = useState()

    useEffect(() => {
        setData(null)
        if (!state.visibleConditionType || !mainState.user?.ID) { return }

        let wheres = [`TRUE`]
        const suffix = mainState.dateMode === DateMode.Monthly ? "_LAST_MONTH" : "_LAST_12_MONTHS"
        const col = `${state.visibleConditionType.replace("_TY", "")}${suffix}`

        First(`
        SELECT
            PERCENTILE_CONT(0.2) WITHIN GROUP ( ORDER BY CASE WHEN ${col} > 0 THEN ${col} END) AS CLASS_1,
            PERCENTILE_CONT(0.4) WITHIN GROUP ( ORDER BY CASE WHEN ${col} > 0 THEN ${col} END) AS CLASS_2,
            PERCENTILE_CONT(0.6) WITHIN GROUP ( ORDER BY CASE WHEN ${col} > 0 THEN ${col} END) AS CLASS_3,
            PERCENTILE_CONT(0.8) WITHIN GROUP ( ORDER BY CASE WHEN ${col} > 0 THEN ${col} END) AS CLASS_4,
            MIN(${col}) AS MIN,
            MAX(${col}) AS MAX
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3_VIEW}_${mainState.user.ID.toString().padStart(8, '0')} AS base
        WHERE ${wheres.join(' AND ')}
        `).then(setData)

    }, [state.visibleConditionType, mainState.dateMode, mainState.user, open])

    return (
        <Dialog open={open} width={400}>
            <DialogTitle>凡例</DialogTitle>
            {!data && <DialogContent><CircularProgress /></DialogContent>}
            {data && (<DialogContent style={styles.root}>
                <Box>
                    <Box style={styles.legendsBox}>
                        <Box><Box style={{...styles.colorHex, background: "#000000"}} /></Box>
                        <Box style={styles.legendsText}>値なし</Box>
                    </Box>
                    <Box style={styles.legendsBox}>
                        <Box><Box style={{...styles.colorHex, background: colorClasses[1]}} /></Box>
                        <Box style={styles.legendsText}>{parseInt(data.MIN).toLocaleString()}〜{parseInt(data.CLASS_1).toLocaleString()}</Box>
                    </Box>
                    <Box style={styles.legendsBox}>
                        <Box><Box style={{...styles.colorHex, background: colorClasses[2]}} /></Box>
                        <Box style={styles.legendsText}>{parseInt(data.CLASS_1).toLocaleString()}〜{parseInt(data.CLASS_2).toLocaleString()}</Box>
                    </Box>
                    <Box style={styles.legendsBox}>
                        <Box><Box style={{...styles.colorHex, background: colorClasses[3]}} /></Box>
                        <Box style={styles.legendsText}>{parseInt(data.CLASS_2).toLocaleString()}〜{parseInt(data.CLASS_3).toLocaleString()}</Box>
                    </Box>
                    <Box style={styles.legendsBox}>
                        <Box><Box style={{...styles.colorHex, background: colorClasses[4]}} /></Box>
                        <Box style={styles.legendsText}>{parseInt(data.CLASS_3).toLocaleString()}〜{parseInt(data.CLASS_4).toLocaleString()}</Box>
                    </Box>
                    <Box style={styles.legendsBox}>
                        <Box><Box style={{...styles.colorHex, background: colorClasses[5]}} /></Box>
                        <Box style={styles.legendsText}>{parseInt(data.CLASS_4).toLocaleString()}〜</Box>
                    </Box>
                </Box>
                <DialogActions>
                    <Button onClick={onClose}>閉じる</Button>
                </DialogActions>
            </DialogContent>)}
        </Dialog>
    )
}

MapLegendView.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default MapLegendView
