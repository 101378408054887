import Draggable from "react-draggable";
import {
    Box,
    Button, IconButton,
    Paper,
    Typography,
} from "@mui/material";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Rnd} from "react-rnd";
import {MainDataContext} from "../../main";
import {
    ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import _ from "lodash";

const PaperComponent = (props) => {
    return (
        <Draggable
            handle="#draggable-tabeloginfo-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            >
            <Paper style={{ pointerEvents: 'none'}}
                               {...props} />
        </Draggable>
    )
}

let width = 800
let height = undefined

let x = window.innerWidth - 800 - 120
const y = 140

const styles = {
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: "8px",
    },
    headerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    dealerCode: {
        color: "#666",
    },
    dealerName: {
        fontWeight: 'bold',
        fontSize: '19px',
        marginLeft: '1rem',
    },
    dealerCat: {
        color: "#747373",
        marginLeft: "1.5rem",
    },
    iconBox: {
        display: 'flex',
        justifyContent: 'start',
        gap: '8px',
    },
    iconCircle: {
        borderRadius: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2px',
        border: '0.2px solid #777'
    },
    iconCircleActive: {
        background: "yellow",
    },
    iconCircleNative: {

    },
    icon: {
        fontSize: "32px",
    },
    iconActive: {

    },
    iconNative: {

    },
    linkBox: {

    },
    linkButton: {

    },
    controlBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        justifyContent: 'end',
        marginRight: '3rem',
    },
    controlButton: {
        width: '120px',
    },
    table: {
        border: '1px solid #000',
        borderCollapse: 'collapse',
        margin: '8px',
    },
    theadItem: {
        width: '120px',
        background: '#6666',
        border: '1px solid #000',
        borderCollapse: 'collapse',
    },
    tableTitle: {
        border: '1px solid #000',
        borderCollapse: 'collapse',
    },
    tableValue: {
        textAlign: 'right',
        paddingRight: '8px',
        border: '1px solid #000',
        borderCollapse: 'collapse',
    },
    addressLineBox: {
        display: 'flex',
        flexDirection: 'row',
    },
    addressTitle: {
        fontWeight: 'bold',
        width: '140px',
    },
    address: {

    },
    copied: {
        fontSize: '9px',
        fontWeight: 'bold',
        color: '#0f920f'
    }
}

const SalesInfoView = (props) => {

    const { state } = useContext(MainDataContext)
    const [mouseEnter, setMouseEnter] = useState()
    const [copied, setCopied] = useState()
    const background = useMemo(() => {
        return mouseEnter ? "white": "#ffffff73"
    }, [mouseEnter])
    const info = useMemo(() => { return {...state.salesInfo?.object?.properties} }, [state.salesInfo])

    useEffect(() => {
        console.log("[SalePoint]", "info", info)
    }, [info])

    const clearCopy = useCallback((type) => {
        if (type !== copied) { return }
        setCopied(null)
    }, [copied])

    const onCopySold = useCallback(() => {
        navigator.clipboard.writeText(info?.ADDR_SOLD_TO)
        setCopied("sold")
        setTimeout(() => clearCopy("sold"), 3000)
    }, [info])

    const onCopyShip = useCallback(() => {
        navigator.clipboard.writeText(info?.ADDR_SHIP_TO)
        setCopied("ship")
        setTimeout(() => clearCopy("ship"), 3000)
    }, [info])

    return state.salesInfo ? (
        <Rnd
            default={{
                x,
                y,
                width,
                height,
            }}
            style={{
                background,
                borderRadius: '8px',
                boxShadow: 'rgb(0, 0, 0) 1px 1px 8px',
                zIndex: 1200,
            }}
        >
            <Box
                style={styles.root}
                onMouseEnter={() => setMouseEnter(true)}
                onMouseLeave={() => setMouseEnter(false)}
            >
                <Box style={styles.headerBox}>
                    <Typography style={styles.dealerCode}>{info?.ZJDLRC}</Typography>
                    <Typography style={styles.dealerName}>{info?.ZJDLRC___T}</Typography>
                </Box>
                <Box style={styles.addressBox}>
                    <Box style={styles.addressLineBox}>
                        <Typography style={styles.addressTitle}>ディーラー住所</Typography>
                        <Typography style={styles.address}>
                            {info?.ADDR_SOLD_TO}
                            <IconButton onClick={onCopySold}><ContentCopyIcon /></IconButton>
                            {copied === "sold" && (<span style={styles.copied}>コピーしました</span>)}
                        </Typography>
                    </Box>
                    <Box style={styles.addressLineBox}>
                        <Typography style={styles.addressTitle}>配達先住所</Typography>
                        <Typography style={styles.address}>
                            {info?.ADDR_SHIP_TO}
                            <IconButton onClick={onCopyShip}><ContentCopyIcon/></IconButton>
                            {copied === "ship" && (<span style={styles.copied}>コピーしました</span>)}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <table style={styles.table}>
                        <thead style={styles.thead}>
                            <th style={styles.theadItem}>区分</th>
                            <th style={styles.theadItem}>BAPC</th>
                            <th style={styles.theadItem}>GP</th>
                            <th style={styles.theadItem}>NSR</th>
                        </thead>
                        <tbody>
                        <tr style={styles.tr}>
                            <th style={styles.tableTitle}>前月</th>
                            <td style={styles.tableValue}>{info?.BAPC_LAST_MONTH?.toLocaleString() ?? "--"}</td>
                            <td style={styles.tableValue}>{info?.GP_LAST_MONTH?.toLocaleString() ?? "--"}</td>
                            <td style={styles.tableValue}>{info?.NSR_LAST_MONTH?.toLocaleString() ?? "--"}</td>
                        </tr>
                        <tr style={styles.tr}>
                            <th style={styles.tableTitle}>過去12ヶ月</th>
                            <td style={styles.tableValue}>{info?.BAPC_LAST_12_MONTHS?.toLocaleString() ?? "--"}</td>
                            <td style={styles.tableValue}>{info?.GP_LAST_12_MONTHS?.toLocaleString() ?? "--"}</td>
                            <td style={styles.tableValue}>{info?.NSR_LAST_12_MONTHS?.toLocaleString() ?? "--"}</td>
                        </tr>
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Rnd>
    ) : null

}

export default SalesInfoView
