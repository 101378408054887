
export const ColumnDefs = [
    {
        headerName: "No",
        valueGetter: e => (e.node?.rowIndex === null || e.node?.rowIndex === undefined) ? null: e.node.rowIndex + 1,
        width: 70,
        cellStyle: {textAlign: "right"},
        filter: false,
        pinned: true,
    },
    {
        headerName: "ディーラーコード",
        field: "ZJDLRC",
        width: 120,
        filter: "agTextColumnFilter",
    },
    {
        headerName: "ディーラー名",
        field: "ZJDLRC___T",
        width: 250,
        filter: "agTextColumnFilter",
    },
    {
        headerName: "ディーラー住所",
        field: "ADDR_SOLD_TO",
        width: 400,
        filter: "agTextColumnFilter",
    },
    {
        headerName: "配達先",
        field: "ADDR_SHIP_TO",
        width: 400,
        filter: "agTextColumnFilter",
    },
    {
        headerName: "BAPC",
        field: "BAPC_TY",
        width: 120,
        filter: "agNumberColumnFilter",
    },
    {
        headerName: "NSR",
        field: "NSR_TY",
        width: 120,
        filter: "agNumberColumnFilter",
    },
    {
        headerName: "GP",
        field: "GP_TY",
        width: 120,
        filter: "agNumberColumnFilter",
    },
]
