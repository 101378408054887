import Draggable from "react-draggable";
import {
    Box,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Rnd} from "react-rnd";
import {MainDataContext} from "../../main";
import {
    AssignmentInd as AssignmentIndIcon,
    AutoGraph as AutoGraphIcon,
    Handshake as HandshakeIcon,
    Storefront as StorefrontIcon
} from "@mui/icons-material";
import _ from "lodash";

const PaperComponent = (props) => {
    return (
        <Draggable
            handle="#draggable-tabeloginfo-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            >
            <Paper style={{ pointerEvents: 'none'}}
                               {...props} />
        </Draggable>
    )
}

let width = 800
let height = undefined

let x = window.innerWidth - 800 - 120
const y = 140

const styles = {
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: "8px",
    },
    headerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    dealerCode: {
        color: "#666",
    },
    dealerName: {
        fontWeight: 'bold',
        fontSize: '19px',
        marginLeft: '1rem',
    },
    dealerCat: {
        color: "#747373",
        marginLeft: "1.5rem",
    },
    iconBox: {
        display: 'flex',
        justifyContent: 'start',
        gap: '8px',
    },
    iconCircle: {
        borderRadius: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2px',
        border: '0.2px solid #777'
    },
    iconCircleActive: {
        background: "yellow",
    },
    iconCircleNative: {

    },
    icon: {
        fontSize: "32px",
    },
    iconActive: {

    },
    iconNative: {

    },
    linkBox: {

    },
    linkButton: {

    },
    controlBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        justifyContent: 'end',
        marginRight: '3rem',
    },
    controlButton: {
        width: '120px',
    }
}

const TabelogInfoView = (props) => {

    const { state, appendSelectedTabelogId, dropSelectedTabelogId } = useContext(MainDataContext)
    const [open, setOpen] = useState(false)
    const [mouseEnter, setMouseEnter] = useState()
    const background = useMemo(() => {
        return mouseEnter ? "white": "#ffffff73"
    }, [mouseEnter])
    const info = useMemo(() => { return {...state.tabelogInfo?.object?.properties} }, [state.tabelogInfo])
    const isAssigned = useMemo(() => {
        if (!info?.IS_ASSIGNED) { return false }
        return JSON.parse(info.IS_ASSIGNED)
    }, [info])


    const hanaMatchBackground = useMemo(() => {
        if (info?.HANA_MATCH_FLAG) {
            switch (info.HANA_MATCH_FLAG.trim()) {
                case "○":
                    return "yellow"
                case "△":
                    return "#98e8f2"
                default:
                    break
            }
        }
        return "#ccc"
    }, [info])

    const sakayaMatchBackground = useMemo(() => {
        if (info?.SAKAYA_MATCH_FLAG) {
            switch (info.SAKAYA_MATCH_FLAG.trim()) {
                case "○":
                    return "yellow"
                case "△":
                    return "#98e8f2"
                default:
                    break
            }
        }
        return "#ccc"
    }, [info])

    const assignedBackground = useMemo(() => isAssigned ? "yellow" : "#ccc", [isAssigned])

    const potentialBackground = useMemo(() => {
        console.log("[PotentialBackground]", info?.NSR_PRED, _.isEmpty(info?.NSR_PRED), info)
        if (!info?.NSR_PRED) { return "#ccc" }
        switch(parseInt(info.NSR_PRED)) {
            case 0:
                return "#636363"
            case 1:
                return "#98e8f2"
            case 2:
                return "yellow"
            default:
                break
        }
        return "#ccc"
    }, [info])

    useEffect(() => {
        setOpen(!!state.tabelogInfo)
    }, [state.tabelogInfo])


    const onSelect = () => {
        console.log(state.tabelogInfo)
        appendSelectedTabelogId(info.TABELOG_ID)
    }

    const onUnselect = () => {
        dropSelectedTabelogId(info.TABELOG_ID)
    }

    return state.tabelogInfo ? (
        <Rnd
            default={{
                x,
                y,
                width,
                height,
            }}
            style={{
                background,
                borderRadius: '8px',
                boxShadow: 'rgb(0, 0, 0) 1px 1px 8px',
                zIndex: 1200,
            }}
        >
            <Box
                style={styles.root}
                onMouseEnter={() => setMouseEnter(true)}
                onMouseLeave={() => setMouseEnter(false)}
            >
                <Box style={styles.headerBox}>
                    <Typography style={styles.dealerCode}>{info?.SAKAYA_DEALER_CODE}</Typography>
                    <Typography style={styles.dealerName}>{info?.SAKAYA_DEALER_NAME}</Typography>
                    <Typography style={styles.dealerCat}>[{info?.CUISINE_CAT_1}]</Typography>
                </Box>
                <Box style={styles.addressBox}>
                    <Typography style={styles.address}>{info?.PREFECTURE}{info?.CITY}</Typography>
                </Box>
                <Box style={styles.iconBox}>
                    <Box style={{...styles.iconCircle, background: hanaMatchBackground}}><HandshakeIcon style={{...styles.icon}} /></Box>
                    <Box style={{...styles.iconCircle, background: sakayaMatchBackground}}><StorefrontIcon style={{...styles.icon}} /></Box>
                    <Box style={{...styles.iconCircle, background: assignedBackground}}><AssignmentIndIcon style={{...styles.icon}} /></Box>
                    <Box style={{...styles.iconCircle, background: potentialBackground}}><AutoGraphIcon style={{...styles.icon}} /></Box>
                </Box>
                {info?.RST_URL && (
                    <Box style={styles.linkBox}>
                        {!state.selectedTabelogIds.includes(info.TABELOG_ID) && (<Button variant="contained" style={styles.selectButton} onClick={onSelect}>この店舗を選択</Button>)}
                        {state.selectedTabelogIds.includes(info.TABELOG_ID) && (<Button variant="contained" color="secondary" style={styles.selectButton} onClick={onUnselect}>この店舗の選択を解除</Button>)}
                        <Button variant="outlined" style={styles.linkButton} onClick={() => window.open(info.RST_URL)}>食べログを表示</Button>
                    </Box>
                )}
            </Box>
        </Rnd>
    ) : null

}

export default TabelogInfoView
