import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Box, CircularProgress, IconButton} from "@mui/material";
import {MainDataContext} from "../../main";

import { Bar } from "react-chartjs-2"
import {
    Chart as ChartJs,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js"
import {Rows} from "../../../manager/carto3";
import dayjs from "dayjs";
import _ from "lodash";
import {OpenInNew as OpenInNewIcon} from "@mui/icons-material";
import {DateMode} from "../../../data/main/state";

ChartJs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
)

export const borderColors = {
    Current: "#4f4fff",
    YoY: "#ff8b4f",
}

export const backgroundColors = {
    Current: "#8c8cfa",
    YoY: "#faaa87",
}

export const graphOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: "bottom",
        },
        title: {
            display: true,
        },
    }
}

const styles = {
    graphBox: {
        position: "relative",
    },
    graphOpenButton: {
        position: 'absolute',
        top: '1px',
        right: '3px',
    },
    graphOpenButtonIcon: {
        fontSize: "16px",
    },
    loading: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "#9898987E",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}

const RootRightView = (props) => {

    const { state, setGraphData } = useContext(MainDataContext)

    const [areaData, setAreaData] = useState()
    const [isTotalLoading, setIsTotalLoading] = useState(false)
    const [isAreaLoading, setIsAreaLoading] = useState(false)

    const getData = () => {
        return null
    }

    //
    // const getData = useCallback((key) => {
    //     if (!areaData) { return null }
    //
    //     let datasets = []
    //     datasets.push({
    //         label: "当月",
    //         data: dates.map(d => {
    //             let v = areaData.find(v => v.DATE === d)
    //             if (!v || !v[key]) { return null }
    //             return v[key]
    //         }),
    //         borderColor: borderColors.Current,
    //         backgroundColor: backgroundColors.Current,
    //     })
    //     datasets.push({
    //         label: "前年同月",
    //         data: dates.map(d => {
    //             let m = dayjs(d).add(-1, "year")
    //             let v = areaData.find(v => v.DATE === m.format("YYYY-MM-DD"))
    //             if (!v || !v[key]) { return null }
    //             return v[key]
    //         }),
    //         borderColor: borderColors.YoY,
    //         backgroundColor: backgroundColors.YoY,
    //     })
    //
    //     return {
    //         labels: dates.map(d => dayjs(d).format("YYYY年MM月")),
    //         datasets
    //     }
    // }, [dates, areaData])

    const bapcGraphData = useMemo(() => getData("BAPC_TY"), [getData])
    const nsrGraphData = useMemo(() => getData("NSR_TY"),[getData])
    const gpGraphData = useMemo(() => getData("GP_TY"),[getData])

    useEffect(() => {
        if (!state.mapViewBounds) {
            setAreaData(null)
            return
        }

        let values = [...state.mapViewBounds]
        let wheres = [`SALES_ID = ${state.user.ID}`]
        setIsAreaLoading(true)

        const suffix = state.dateMode === DateMode.Monthly ? "LAST_MONTH" : "LAST_12_MONTHS"

        Rows(`
        WITH bounds AS (
          SELECT
            ST_MAKEPOLYGON(
              TO_GEOGRAPHY(
                CONCAT('LINESTRING('
                  , :1, ' ', :2, ', '
                  , :3, ' ', :2, ', '
                  , :3, ' ', :4, ', '
                  , :1, ' ', :4, ', '
                  , :1, ' ', :2, '
                )')
              )
            ) AS bbox
        )
        SELECT
          DATE,
          SUM(BAPC_${suffix}) AS BAPC_TY,
          SUM(NSR_${suffix}) AS NSR_TY,
          SUM(GP_${suffix}) AS GP_TY
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_POINT} AS point, bounds
        INNER JOIN (
            SELECT ID AS SALES_ID, H3_INDICES_LEVEL11
            FROM ${process.env.REACT_APP_CARTO_TABLE_SALES}
        ) AS sales
        ON ARRAY_CONTAINS(POINT.H3::variant, sales.H3_INDICES_LEVEL11)
        WHERE ST_CONTAINS(bbox, GEOM) AND ${wheres.join(' AND ')}
        GROUP BY 1
        `, values).then(e => {
            setAreaData(e)
            setIsAreaLoading(false)
        })

    }, [state.mapViewBounds, state.user, state.dateMode])

    const getGraphOption = title => {
        let opt = JSON.parse(JSON.stringify(graphOptions))
        return _.merge(opt, {plugins: {title: {text: title}}})
    }

    return (
        <Box>
            <Box style={{width: "400px", height: "calc(100vh - 160px)", margin: "1rem", overflowY: "auto"}}>
                <Box style={styles.graphBox}>
                    {nsrGraphData && (
                        <>
                            <IconButton
                                style={styles.graphOpenButton}
                                onClick={() => setGraphData({data: nsrGraphData, title: "NSRグラフ"})}>
                                <OpenInNewIcon style={styles.graphOpenButtonIcon} />
                            </IconButton>
                            <Bar
                                options={getGraphOption("NSRグラフ")}
                                data={nsrGraphData}
                                height={300}/>
                        </>
                    )}
                    {(isTotalLoading || isAreaLoading) && (
                        <Box style={styles.loading}><CircularProgress /></Box>
                    )}
                </Box>
                <Box style={styles.graphBox}>
                    {bapcGraphData && (
                        <>
                            <IconButton
                                style={styles.graphOpenButton}
                                onClick={() => setGraphData({data: bapcGraphData, title: "BAPCグラフ"})}>
                                <OpenInNewIcon style={styles.graphOpenButtonIcon} />
                            </IconButton>
                            <Bar
                                options={getGraphOption("BAPCグラフ")}
                                data={bapcGraphData}
                                height={300}/>
                        </>
                    )}
                    {(isTotalLoading || isAreaLoading) && (
                        <Box style={styles.loading}><CircularProgress /></Box>
                    )}
                </Box>
                <Box style={styles.graphBox}>
                    {gpGraphData && (
                        <>
                            <IconButton
                                style={styles.graphOpenButton}
                                onClick={() => setGraphData({data: gpGraphData, title: "GPグラフ"})}>
                                <OpenInNewIcon style={styles.graphOpenButtonIcon} />
                            </IconButton>
                            <Bar
                                options={getGraphOption("GPグラフ")}
                                data={gpGraphData}
                                height={300}
                            />
                        </>
                    )}
                    {(isTotalLoading || isAreaLoading) && (
                        <Box style={styles.loading}><CircularProgress /></Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default RootRightView
