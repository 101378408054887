import {useContext, useEffect, useMemo, useState} from "react";
import {MainDataContext} from "../../../main";
import IconImage from "../../../../resources/img/map-pin3.png"
import {IconLayer} from "deck.gl";


const UseRootMapTabelogSelectLayer = (props) => {

    const { state } = useContext(MainDataContext)
    const [layer, setLayer] = useState()

    useEffect(() => {

        if (!state.tabelogInfo) { return }

        console.log(state.tabelogInfo)

        const l = new IconLayer({
            id: "tabelog_select",
            data: [
                {name: "Selected", coordinates: state.tabelogInfo.object.geometry.coordinates, icon: "marker"},
            ],
            iconAtlas: IconImage,
            iconMapping: {
                marker: {
                    x: 0,
                    y: 0,
                    width: 26,
                    height: 32,
                    anchorY: 32,
                    anchorX: 13,
                }
            },
            sizeScale: 1,
            getIcon: d => 'marker',
            getSize: 40,
            getPosition: d => d.coordinates,
            getColor: d => [255, 0, 0],
        })
        console.log(l)
        setLayer(l)

        return () => {
            setLayer(null)
        }
    }, [state.tabelogInfo])


    return layer
}

UseRootMapTabelogSelectLayer.propTypes = {

}

export default UseRootMapTabelogSelectLayer
