import React, {useCallback, useContext, useEffect, useMemo, useState} from "react"
import PropTypes from "prop-types";
import {MapDataContext} from "../index";
import BaseView from "./base";
import {First} from "../../../../manager/carto3";
import {Box, Button, Slider, TextField} from "@mui/material";
import {ConditionType} from "../../../../data/map/state";
import _ from "lodash";
import {DateMode} from "../../../../data/main/state";
import LegendView from "../legend";

const styles = {
    root: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    sliderBox: {
        margin: "0 2rem",
        width: "500px",
    },
    inputsBox: {
        display: "flex",
        flexDirection: "row",
        gap: "8px",
    },
}

const RootMapConditionRangeView = React.memo(({type, dateMode, userId, unit, title}) => {

    const { state, appendCondition, clearCondition } = useContext(MapDataContext)

    const [min, setMin] = useState()
    const [max, setMax] = useState()
    const [inputMin, setInputMin] = useState()
    const [inputMax, setInputMax] = useState()
    const [marks, setMarks] = useState()
    const [openLegends, setOpenLegends] = useState()

    const text = useMemo(() => {
        if (state.conditions[type]) {
            return `${parseInt(state.conditions[type][0]).toLocaleString()}${unit ?? ""}〜${parseInt(state.conditions[type][1]).toLocaleString()}${unit ?? ""}`
        } else {
            return "未設定"
        }
    }, [state.conditions, type, unit])

    const inputMinError = useMemo(() => {
        if (isNaN(inputMin)) { return true }
        if (inputMin < min) { return true }
        if (state.conditions[type] && state.conditions[type][1] < inputMin) { return true}
        return false
    }, [inputMin, min, state.conditions, type])

    const inputMaxError = useMemo(() => {
        if (isNaN(inputMax)) { return true }
        if (inputMax > max) { return true }
        if (state.conditions[type] && state.conditions[type][0] > inputMax) { return true }
        return false
    }, [inputMax, max, state.conditions, type])

    useEffect(() => {
        // let minCol = `${type}_MIN`
        // let maxCol = `${type}_MAX`
        // if (type === ConditionType.POPULATION) {
        //     minCol = `${type}_MAX`
        //     maxCol = `${type}_MAX`
        // }

        const col = `${type.replace("_TY", "")}_${dateMode === DateMode.Monthly ? "LAST_MONTH" : "LAST_12_MONTHS"}`

        First(`
        SELECT
            MIN(${col}) AS MIN,
            MAX(${col}) AS MAX
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3_VIEW}_${userId.toString().padStart(8, '0')} AS base
        `).then(row => {
            setMin(row.MIN)
            setMax(row.MAX)
            let range = (row.MAX - row.MIN) / 5
            let m = []
            for(let i = 0; i < 5; i++) {
                m.push({
                    value: parseInt(row.MIN + range * i),
                    label: `${parseInt(row.MIN + range * i).toLocaleString()}${unit ?? ""}`,
                })
            }
            m.push({
                value: parseInt(row.MAX),
                label: `${parseInt(row.MAX).toLocaleString()}${unit ?? ""}`
            })
            setMarks(m)
        })
    }, [])

    useEffect(() => {
        if (!min || !max) { return }
        let [n, x] = state.conditions[type] ? state.conditions[type] : [min, max]
        setInputMin(n)
        setInputMax(x)
    }, [min, max, state.conditions])

    const onChangeSlider = _.debounce((e) => {
        if (e.target.value[0] !== min || e.target.value[1] !== max) {
            console.log("[Range]", "update condition", e.target.value)
            appendCondition({[type]: e.target.value})
        }
    }, 100)

    const onChangeMin = useCallback((e) => {
        if (inputMinError) { return }
        console.log("[Range]", "on change min", inputMin)
        appendCondition({[type]: [parseInt(inputMin), state.conditions[type] ? state.conditions[type][1] : max]})
    }, [state.conditions, inputMin, inputMinError])

    const onChangeMax = useCallback(e => {
        if (inputMaxError) { return }
        console.log("[Range]", "on change max", inputMax)
        appendCondition({[type]: [state.conditions[type] ? state.conditions[type][0] : min, parseInt(inputMax)]})
    }, [state.conditions, inputMax, inputMaxError])

    const sliderValue = useMemo(() => {
        if (state.conditions[type]) {
            return state.conditions[type]
        }
        return [inputMin ?? min, inputMax ?? max]
    }, [inputMin, inputMax, state.conditions, min, max])

    return (
        <BaseView text={text} type={type} title={title} key={`${type}-base-view`}>
            <Box style={styles.root}>
                <Box style={styles.sliderBox}>
                    <Slider
                        key={`${type}-slider`}
                        min={min}
                        max={max}
                        value={sliderValue}
                        onChange={onChangeSlider}
                        valueLabelDisplay="auto"
                        marks={marks}
                    />
                </Box>
                <Box style={styles.inputsBox}>
                    <TextField key={`${type}-min-input`} style={styles.input} deafultValue={inputMin} error={inputMinError} size="small" value={inputMin} onBlur={onChangeMin} onChange={e => setInputMin(e.target.value)} />
                    <TextField key={`${type}-max-input`} style={styles.input} defaultValue={inputMax} error={inputMaxError} size="small" value={inputMax} onBlur={onChangeMax} onChange={e => setInputMax(e.target.value)} />
                    <Box style={{flexGrow: 1}} />
                    <Button onClick={() => setOpenLegends(true)}>凡例</Button>
                    <Button key={`${type}-clear-button`} onClick={() => clearCondition(type)}>クリア</Button>
                </Box>
            </Box>
            <LegendView open={openLegends} onClose={() => setOpenLegends(false)} />
        </BaseView>
    )
})

RootMapConditionRangeView.propTypes = {
    type: PropTypes.string.isRequired,
    dateMode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    unit: PropTypes.string,
}

export default RootMapConditionRangeView
