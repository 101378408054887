import {DataType, LOCAL_STORAGE_KEY} from "./state";

export const ActionType = {
    SetViewMode: "SET_VIEW_MODE",
    SetFilterModel: "SET_FILTER_MODEL",
    SetViewDirection: "SET_VIEW_DIRECTION",
    SetSeparatorRate: "SET_SEPARATOR_RATE",
    SetMapInit: "SET_MAP_INIT",
    SetSelectedH3List: "SET_SELECTED_H3_LIST",
    SetListH3List: "SET_LIST_H3_LIST",
    SetSelectedTabelogIds: "SET_SELECTED_TABELOG_IDS",
    SetFilter: "SET_FILTER",
    AppendSelectedTabelogId: "APPEND_SELECTED_TABELOG_ID",
    DropSelectedTabelogId: "DROP_SELECTED_TABELOG_ID",
    SetShowSelectedOnly: "SET_SHOW_SELECTED_ONLY",
    SetMapViewBounds: "SET_MAP_VIEW_BOUNDS",
    SetGraphData: "SET_GRAPH_DATA",
    SetIsDragging: "SET_IS_DRAGGING",
    SetUser: "SET_USER",
    SetDataType: "SET_DATA_TYPE",
    SetFilterModelSales: "SET_FILTER_MODEL_SALES",
    SetDateMode: "SET_DATE_MODE",
    SetTabelogInfo: "SET_TABELOG_INFO",
    SetSelectedList: "SET_SELECTED_LIST",
    SetSalesInfo: "SET_SALES_INFO",
    SetTabelogFilterModel: "SET_TABELOG_FILTER_MODEL",
    SetSalesFilterModel: "SET_SALES_FILTER_MODEL",
}


const MainDataReducer = (state, action) => {

    switch (action.type) {
        case ActionType.SetViewMode:
            state = {...state, viewMode: action.value}
            break
        case ActionType.SetFilterModel:
            state = {...state, filterModel: action.value}
            break
        case ActionType.SetViewDirection:
            state = {...state, viewDirection: action.value}
            break
        case ActionType.SetSeparatorRate:
            state = {...state, separatorRate: action.value}
            break
        case ActionType.SetMapInit:
            state = {...state, mapInit: action.value}
            break
        case ActionType.SetSelectedH3List:
            state = {...state, selectedH3List: action.value}
            break
        case ActionType.SetListH3List:
            state = {...state, listH3List: action.value}
            break
        case ActionType.SetSelectedTabelogIds:
            state = {...state, selectedTabelogIds: action.value}
            break
        case ActionType.SetFilter:
            state = {...state, filter: action.value}
            break
        case ActionType.AppendSelectedTabelogId:
            state = {...state, selectedTabelogIds: Array.from(new Set([...state.selectedTabelogIds, action.value]))}
            break
        case ActionType.DropSelectedTabelogId:
            state = {...state, selectedTabelogIds: [...state.selectedTabelogIds.filter(id => id !== action.value)]}
            break
        case ActionType.SetShowSelectedOnly:
            return {...state, showSelectedOnly: action.value}
        case ActionType.SetMapViewBounds:
            return {...state, mapViewBounds: action.value}
        case ActionType.SetGraphData:
            return {...state, graphData: action.value}
        case ActionType.SetIsDragging:
            return {...state, isDragging: action.value}
        case ActionType.SetUser:
            state = {...state, user: action.value}
            break
        case ActionType.SetDataType:
            return {...state, dataType: action.value}
        case ActionType.SetFilterModelSales:
            state = {...state, filterModel: action.value}
            break
        case ActionType.SetDateMode:
            state = {...state, dateMode: action.value}
            break
        case ActionType.SetTabelogInfo:
            return {...state, tabelogInfo: action.value}
        case ActionType.SetSalesInfo:
            return {...state, salesInfo: action.value}
        case ActionType.SetSelectedList:
            return {...state, selectedList: action.value}
        case ActionType.SetTabelogFilterModel:
            state = {...state, tabelogFilterModel: action.value}
            break
        case ActionType.SetSalesFilterModel:
            state = {...state, salesFilterModel: action.value}
            break
        default:
            return state
    }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        ...state,
        showConditionType: null, // 値を保存しない
        showSelectedOnly: false,
        mapViewBounds: null,
        graphData: null,
        isDragging: false,
        dataType: DataType.TABELOG,
        tabelogInfo: null,
        salesInfo: null,
        selectedList: null,
    }))

    return state
}

export default MainDataReducer
